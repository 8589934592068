import React from 'react';
import Webpages from './pages';

function App() {

  return (
    <div className="App">
      <Webpages />
    </div>
  );
}

export default App;
